import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "About",
      description1 = `My name is Javier Silva Ortiz a.k.a Json. I'm passionate about disruptive technologies. Enthusiast of enterprise architecture and corporate agility. Engineer by mind and Entrepreneur by vision.`,
      description2 = `My 17 years of experience span an exciting gamut of Freelance work, R+D+I departments, Software Houses, and Product Companies, in cross-functional and multi-cultural environments. As a result, I'm a battle tested professional; very resilient and creative.`,
      description3 = `As a manager, I've driven companies and teams to high performance, low costs, and meeting deadlines with operational efficiency. My biggest challenge was to make a success of failling projects and restore degraded relationships with clients.`,
      description4 = `As an engineer, my knack for sophisticated, yet simple and elegant solutions made me a top choice among employers. My biggest challenge was reshaping myself as I transitioned into senior and technical leadership roles.`,
      description5 = `As an executive leader, I've defined cross-functional strategies for high productivity and product development efficiency.`;
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description1}</p>
                    <p className="description">{description2}</p>
                    <p className="description">{description3}</p>
                    <p className="description">{description4}</p>
                    <p className="description">{description5}</p>
                  </div>
                  {/* <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
